<template>
	<div class="content-wrap">
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="会议状态">
				<Select
					v-model="searchParams.status"
					:transfer="true"
					placeholder="请选择会议状态"
				>
					<Option :value="list.k" v-for="list in metStatus" :key="list.k">{{
						list.v
					}}</Option>
				</Select>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="会议标题">
				<Input placeholder="请输入会议标题" v-model="searchParams.title" />
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button
				@click="handleAddShow"
				v-if="!(systemActionPermissions.indexOf('btn_meeting_add') === -1)"
				>新增</Button
			>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="page"
		></CtmsDataGrid>
		<Add
			:visible="addVisible"
			@onCancel="addVisible = false"
			@onOk="handleAddOk"
			:updateId="updateId"
		></Add>
		<ViewDetail
			:visible="viewVisible"
			@onCancel="viewVisible = false"
			:viewId="viewId"
		></ViewDetail>
		<UploadFile
			:status="status"
			:visible="uploadVisible"
			:uploadId="uploadId"
			@onCancel="uploadVisible = false"
		></UploadFile>
		<Users
			:status="status"
			:visible="UsersVisible"
			@onCancel="UsersVisible = false"
			@onOk="handlecheckUsers"
			:id="role"
		></Users>
	</div>
</template>

<script>
import api from "@/api/meeting/meeting"
import { getEnumerateByKey } from "@/utils/util"
import { mapState } from "vuex"
import ViewDetail from "./ViewDetail.vue"
import UploadFile from "./UploadFile.vue"
import Users from "./Users.vue"
import Add from "./Add.vue"

const { apiGetPage, apiDelete } = api

export default {
	name: "index",
	components: {
		Add,
		ViewDetail,
		UploadFile,
		Users
	},
	data() {
		return {
			listData: [],
			columns: [
				{
					title: "会议主题",
					key: "title",
					minWidth: 300
				},
				{
					title: "会议时间",
					key: "time",
					minWidth: 300,
					render: (h, params) =>
						h("span", {}, `${params.row.startTime} - ${params.row.endTime}`)
				},
				{
					title: "会议地点",
					key: "address",
					minWidth: 300
				},
				{
					title: "会议状态",
					key: "status",
					minWidth: 120,
					render: (h, params) => {
						const statusStr = getEnumerateByKey(
							this.metStatus,
							params.row.status
						)
						return h("span", {}, statusStr ? statusStr.v : "已取消")
					}
				},
				{
					title: "操作",
					key: "action",
					width: 250,
					fixed: "right",
					renderButton: params => {
						const actionList = [
							{
								label: "查看",
								on: {
									click: this.handleViewShow
								}
							},
							{
								label: "修改",
								on: {
									click: this.handleUpdateShow
								}
							},
							{
								label: "附件",
								on: {
									click: this.uploadAnnex
								}
							},
							{
								label: "人员",
								on: {
									click: this.handleShowUsers
								}
							},
							{
								label: "取消",
								on: {
									click: this.handleSwitchState
								},
								confirm: {
									title: "您确认取消该会议？"
								}
							}
						]
						// 进行中
						if (params.row.status === 1) {
							actionList[1] = null
							actionList[4] = null
						}
						// 已结束
						if (params.row.status === 2) {
							actionList[1] = null
							actionList[4] = null
						}
						// 已取消
						if (params.row.status === 3) {
							actionList[1] = null
							actionList[4] = null
						}
						return actionList.filter(item => !!item)
					}
				}
			],
			UsersVisible: false,
			uploadVisible: false,
			uploadId: "",
			loading: false,
			addVisible: false,
			viewVisible: false,
			updateId: "",
			viewId: "",
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			},
			searchParams: {
				title: "",
				status: ""
			},
			oldSearchParams: {
				title: "",
				status: ""
			},
			status: 0,
			role: ""
		}
	},
	created() {
		this.initList()
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 130
		}),
		...mapState("permission", ["systemActionPermissions"]),
		metStatus() {
			return this.$store.state.enumerate.MEETING_STATUS
		}
	},
	methods: {
		initList() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetPage({
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res) {
					this.listData = res.data.list
					this.page.total = res.data.total
				}
				this.loading = false
			})
		},
		// 上传附件
		uploadAnnex({ row }) {
			this.uploadId = row.id
			this.status = row.status
			this.uploadVisible = true
		},
		// 新增显示
		handleAddShow() {
			this.addVisible = true
			this.updateId = ""
		},
		// 新增成功刷新列表
		handleAddOk() {
			this.addVisible = false
			this.initList()
		},

		// 修改显示
		handleUpdateShow({ row }) {
			this.addVisible = true
			this.updateId = row.id
		},
		handlecheckUsers() {
			this.UsersVisible = false
			this.initList()
		},
		// 查看人员
		handleShowUsers({ row }) {
			this.UsersVisible = true
			this.role = row.id
			this.status = row.status
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 重置
		reset() {
			this.searchParams = {
				title: "",
				status: ""
			}
			this.searchHandle()
		},
		searchHandle() {
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.page.current = 1
			this.$nextTick(() => {
				this.initList()
			})
		},
		// 禁用
		handleSwitchState({ row }) {
			this.$asyncDo(async () => {
				this.loading = true
				let res = false
				res = await apiDelete({ id: row.id })
				if (res) {
					this.$Message.success("操作成功！")
					this.initList()
				} else {
					this.loading = false
				}
			})
		},
		// 查看显示
		handleViewShow({ row }) {
			this.viewVisible = true
			this.viewId = row.id
		}
	},
	watch: {}
}
</script>
