<template>
	<Modal
		:title="title"
		:value="visible"
		width="700"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="项目" prop="projIds">
						<Select
							v-model="formData.projIds"
							:label-in-value="true"
							:multiple="true"
							style="width: 318px"
							placeholder="请选择"
							:disabled="!!updateId"
						>
							<Option
								:value="list.projectId"
								v-for="list in proLists"
								:key="list.projectId"
								:title="list.projectName"
								>{{ list.projectName }}
							</Option>
						</Select>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="会议标题" prop="title">
						<Input v-model="formData.title" placeholder="请输入会议标题" />
					</FormItem>
				</Col>
			</Row>
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="会议开始时间" prop="startTime">
						<DatePicker
							type="datetime"
							placeholder="请选择会议开始时间"
							style="width: 100%"
							:clearable="false"
							v-model="formData.startTime"
							format="yyyy-MM-dd HH:mm:ss"
						></DatePicker>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="会议结束时间" prop="endTime">
						<DatePicker
							type="datetime"
							placeholder="请选择会议结束时间"
							style="width: 100%"
							:clearable="false"
							v-model="formData.endTime"
							format="yyyy-MM-dd HH:mm:ss"
						></DatePicker>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="会议地点" prop="address">
						<Input v-model="formData.address" placeholder="请输入会议地点" />
					</FormItem>
				</Col>
				<Col span="24">
					<FormItem label="会议简介" prop="content">
						<Input
							type="textarea"
							row="5"
							v-model="formData.content"
							placeholder="请输入会议简介"
						/>
					</FormItem>
				</Col>
			</Row>
			<ExtraForm
				@extraValueChange="(key, value) => handleExtraValueChange(key, value)"
				:list="extraList"
				:data="formData"
			></ExtraForm>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button type="primary" @click="onOk" :loading="loading">确定 </Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/meeting/meeting"
import ExtraForm from "@/components/BusinessComponents/ExtendForm/ExtraForm.vue"
import ExtraMixin from "@/mixins/ExtraMixin"

const { apiGet, apiAdd, apiUpdate, getProjList } = api

export default {
	name: "Add",
	props: ["visible", "typeList", "updateId"],
	mixins: [ExtraMixin],
	components: {
		ExtraForm
	},
	data() {
		return {
			loading: false,
			formData: {
				projIds: ""
			},
			title: "",
			rules: {
				title: {
					required: true,
					message: "会议标题不能为空",
					trigger: "blur"
				},
				content: {
					required: true,
					message: "会议内容不能为空",
					trigger: "blur"
				},
				address: {
					required: true,
					message: "地址不能为空",
					trigger: "blur"
				},
				endTime: {
					required: true,
					message: "会议结束时间不能为空",
					trigger: "blur change",
					type: "date"
				},
				startTime: {
					required: true,
					message: "会议开始时间不能为空",
					trigger: "blur change",
					type: "date"
				}
			},
			proLists: [],
			extraList: []
		}
	},
	created() {
		this.getproLists()
		this.getExtraList("MEETING")
	},
	watch: {
		visible() {
			if (this.visible) {
				this.formData = {
					projIds: ""
				}
				this.$refs.form.resetFields()
				this.title = "新增会议"
				if (this.updateId) {
					this.title = "修改会议"
					this.$asyncDo(async () => {
						this.loading = true
						const res = await apiGet({ id: this.updateId })
						if (res) {
							this.formData = {
								...this.formData,
								...res.data,
								...JSON.parse(res.data.extraData)
							}
						}
						this.loading = false
					})
				}
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		async onOk() {
			const valid = await this.$refs.form.validate()
			if (valid) {
				this.loading = true
				let res = false
				if (this.updateId) {
					res = await apiUpdate({
						...this.formData,
						id: this.updateId,
						extraData: this.getExtraData()
					})
				} else {
					res = await apiAdd({
						...this.formData,
						extraData: this.getExtraData()
					})
				}
				if (res) {
					this.$emit("onOk")
					this.$Message.success("操作成功！")
				}
				this.loading = false
			}
		},
		getproLists() {
			this.$asyncDo(async () => {
				const res = await getProjList()
				if (res) {
					this.proLists = res.data
				}
			})
		}
	}
}
</script>
