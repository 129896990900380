<template>
	<Modal
		title="人员列表"
		:value="visible"
		width="350"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>
		<Tree
			:data="treeData"
			show-checkbox
			ref="UsersTree"
			class="left-tree"
		></Tree>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
			<Button type="primary" @click="onOk" :loading="loading">确定 </Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/meeting/meeting"

const { getUsersTree, setUsersTree } = api
const transformTreeData = (data, status) => {
	if (data && data.length) {
		return data.map(item =>
			Object.assign(item, {
				children: transformTreeData(item.leaf, status),
				title: item.name,
				id: item.id,
				key: item.id,
				disableCheckbox: [0, 1].indexOf(status) === -1
				// expand: true,
			})
		)
	}
	return []
}
export default {
	name: "Users",
	props: ["visible", "id", "status"],
	data() {
		return {
			loading: false,
			treeData: [],
			listData: [],
			parentNode: []
		}
	},
	watch: {
		visible() {
			if (this.visible && this.id) {
				this.$asyncDo(async () => {
					this.loading = true
					const res = await getUsersTree({ id: this.id })
					if (res) {
						this.treeData = transformTreeData(res.data, this.status)
					}
					this.loading = false
				})
			}
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			const checkUsers = this.$refs.UsersTree.getCheckedNodes().filter(
				o => o.type === "user"
			)
			const userIds = checkUsers.map(item => item.id)
			this.$asyncDo(async () => {
				this.loading = true
				const res = await setUsersTree({
					id: this.id,
					userIds
				})
				if (res) {
					this.$emit("onOk")
					this.$Message.success("操作成功！")
				}
				this.loading = false
			})
		}
	}
}
</script>

<style scoped></style>
