<template>
	<div>
		<Modal
			:title="title"
			:value="visible"
			width="700"
			:mask-closable="false"
			@on-cancel="onCancel"
			class="control"
		>
			<Spin fix v-if="loading"></Spin>
			<Form
				:model="formData"
				label-position="top"
				ref="controlForm"
				:rules="ruleValidate"
			>
				<Row :gutter="32">
					<Col span="12">
						<FormItem label="组件类型" prop="types">
							<Cascader
								:data="controlList"
								v-model="formData.types"
								@on-change="onControlChange"
							></Cascader>
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem label="组件code" prop="code">
							<Input v-model.trim="formData.code" :disabled="true"></Input>
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem label="组件名称" prop="name">
							<Input
								v-model.trim="formData.name"
								placeholder="请输入组件名称"
							></Input>
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem label="所属标签" prop="tag">
							<Input
								v-model.trim="formData.tag"
								placeholder="请输入所属标签"
							></Input>
						</FormItem>
					</Col>
					<Col span="12" v-if="elecSignShow && env === 'shenzhenrenmin'">
						<FormItem label="签名调用方式" prop="signType">
							<Select v-model="formData.signType">
								<Option :value="0">手动</Option>
								<Option :value="1">自动</Option>
							</Select>
						</FormItem>
					</Col>
					<Col span="12" v-if="elecSignShow">
						<FormItem label="组件全局唯一编码" prop="signAlias">
							<Input
								v-model.trim="formData.signAlias"
								placeholder="请输入全局唯一编码"
							></Input>
						</FormItem>
					</Col>
					<Col span="12" v-if="operatorNameShow">
						<FormItem label="组件全局唯一编码" prop="operatorAlias">
							<Input
								v-model.trim="formData.operatorAlias"
								placeholder="请输入全局唯一编码"
							></Input>
						</FormItem>
					</Col>
					<Col span="12" v-if="!elecSignShow && !operatorNameShow">
						<FormItem label="组件宽度" prop="col">
							<Select v-model="formData.col" :disabled="isTable">
								<Option :value="6">一行4列</Option>
								<Option :value="8">一行3列</Option>
								<Option :value="12">一行2列</Option>
								<Option :value="24">一行1列</Option>
							</Select>
						</FormItem>
					</Col>
					<Col
						span="12"
						v-if="!signatureShow && !textPromptShow && !elecSignShow && !operatorNameShow"
					>
						<FormItem label="提示信息" prop="placeholder">
							<Input
								v-model.trim="formData.placeholder"
								placeholder="请输入提示信息"
							></Input>
						</FormItem>
					</Col>
					<!-- <Col span="12" v-if="selectShow">
          <FormItem label="是否可以筛选选项" >
            <Select v-model="formData.isFilter" >
              <Option :value="0">可筛选</Option>
              <Option :value="1">不筛选</Option>
            </Select>
          </FormItem>
        </Col>-->
					<Col span="12" v-if="textPromptShow">
						<FormItem label="是否在页面显示组件名称" prop="isShowName">
							<Select v-model="formData.isShowName">
								<Option value="Y">是</Option>
								<Option value="N">否</Option>
							</Select>
						</FormItem>
					</Col>
				</Row>
				<Row :gutter="32" v-if="textPromptShow">
					<Col span="24">
						<FormItem label="纯文字提示信息">
							<Input
								v-model.trim="formData.textPrompt"
								type="textarea"
								:autosize="{ minRows: 2 }"
								placeholder="请输入纯文字提示信息"
							></Input>
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem label="纯文字提示信息，首行缩进字符数" prop="textIndent">
							<Select v-model="formData.textIndent">
								<Option value="0">首行不缩进</Option>
								<Option value="24">首行缩进2个字符</Option>
								<Option value="48">首行缩进4个字符</Option>
							</Select>
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem label="纯文字提示信息，文字颜色">
							<ColorPicker
								class="color-picker-wrap"
								v-model="textColor"
								recommend
							/>
						</FormItem>
					</Col>
				</Row>
				<Row :gutter="32" v-if="!elecSignShow && !operatorNameShow">
					<Col span="12">
						<FormItem label="是否项目信息" prop="isProject">
							<Select
								v-model="formData.isProject"
								@on-change="val => handleChangePro(val)"
							>
								<Option value="1">是</Option>
								<Option value="0">否</Option>
							</Select>
						</FormItem>
					</Col>
					<Col
						span="12"
						v-if="
							formData.types !== '' &&
							formData.isRequest !== '2' &&
							(formData.label === '单选框' ||
								formData.label === '下拉选择器' ||
								isShowText)
						"
					>
						<FormItem label="回填至审批内容" prop="isFillRemark">
							<Select v-model="formData.isFillRemark">
								<Option value="1">是</Option>
								<Option value="0">否</Option>
							</Select>
						</FormItem>
					</Col>
					<Col span="12" v-if="showProject">
						<FormItem label="组件全局唯一编码" prop="alias">
							<Input
								v-model.trim="formData.alias"
								placeholder="请输入全局唯一编码"
							></Input>
						</FormItem>
					</Col>
					<Col
						span="12"
						v-if="formData.isProject === '1' && showProject && !textPromptShow"
					>
						<FormItem
							label="流程内表单修改后，项目信息是否同步此数据"
							prop="bindChangeable"
						>
							<Select v-model="formData.bindChangeable">
								<Option value="1">同步</Option>
								<Option value="0">不同步</Option>
							</Select>
						</FormItem>
					</Col>
					<Col span="12" v-if="!textPromptShow && formData.global">
						<FormItem
							label="同一流程多次启动时，是否回填该表单组件"
							prop="placeholder"
						>
							<Select v-model="formData.global" placeholder="请选择">
								<Option label="回填" value="Y">回填</Option>
								<Option label="不回填" value="N">不回填</Option>
							</Select>
						</FormItem>
					</Col>
					<Col span="12" v-if="requestShow">
						<FormItem label="是否请求接口数据" prop="isRequest">
							<Select
								v-model="formData.isRequest"
								@on-change="handleChangeRequestType"
							>
								<Option value="1">系统接口</Option>
								<Option value="2">角色接口</Option>
								<Option value="0">否</Option>
							</Select>
						</FormItem>
					</Col>
					<Col span="12">
						<FormItem
							label="选择请求接口"
							prop="apiCode"
							v-if="formData.isRequest === '1' || formData.isRequest === '2'"
						>
							<template v-if="formData.isRequest === '1'">
								<Select
									v-model="formData.apiCode"
									@on-change="val => handleChangeRequest(val, 'sys')"
								>
									<!--此处禁用而不是删除研究者 researcher，是为了兼容以前的旧数据；需要配置新的研究者的话，请选择角色接口-->
									<Option
										v-for="(item, index) in apiList"
										:disabled="item.code === 'researcher'"
										:key="index"
										:value="item.code"
										>{{ item.name }}</Option
									>
								</Select>
							</template>
							<template v-else-if="formData.isRequest === '2'">
								<Select
									v-model="formData.apiCode"
									@on-change="val => handleChangeRequest(val, 'role')"
								>
									<Option
										v-for="(item, index) in roleList"
										:key="index"
										:value="item.id"
										>{{ item.name }}</Option
									>
								</Select>
							</template>
						</FormItem>
					</Col>
				</Row>
				<Row :gutter="32" v-if="!elecSignShow && !operatorNameShow">
					<Col span="12">
						<FormItem label="是否需要组件联动" prop="isLinkage">
							<Select v-model="formData.isLinkage">
								<Option value="1">是</Option>
								<Option value="0">否</Option>
							</Select>
						</FormItem>
					</Col>
					<Col
						v-if="formData.isLinkage === '1'"
						span="24"
						class="linkage-rule-wrap"
					>
						<p class="title-rule">组件联动——显示隐藏</p>
						<Row style="margin-bottom: 5px" :gutter="8">
							<Col span="10">
								<FormItem label="选择联动组件" prop="target">
									<Select
										class="linkage-select"
										filterable
										placeholder="请选择需要根据哪个组件进行联动"
										v-model="formData.target"
									>
										<Option
											v-for="(op, index) in componentList"
											:value="op.code"
											:key="index"
											>{{ op.name }}</Option
										>
									</Select>
								</FormItem>
							</Col>
							<Col span="4">
								<FormItem label="运算符" prop="operator">
									<Select v-model="formData.operator" placeholder="运算符">
										<Option value="==">等于</Option>
										<Option value="!=">不等于</Option>
									</Select>
								</FormItem>
							</Col>
							<Col span="10">
								<FormItem label="联动组件值" prop="targetVal">
									<Input
										placeholder="联动组件值为XX时，显示当前组件"
										v-model.trim="formData.targetVal"
									/>
								</FormItem>
							</Col>
						</Row>
					</Col>
					<Col span="12" v-if="autoGenerateShow">
						<FormItem prop="autoGenerateRule" label="编号生成规则">
							<Poptip
								title="编号生成规则"
								trigger="hover"
								transfer
								placement="top"
								class="pop-rule"
							>
								<Input
									style="width: 100%"
									v-model.trim="formData.autoGenerateRule"
									placeholder="点击查看编号生成规则，并输入"
								/>
								<div slot="content" class="formitem-tooltip-content">
									<p><span>{year}</span>当前年份（四位，年累积）</p>
									<p><span>{month}</span>当前月份（两位，月累积）</p>
									<p><span>{day}</span>当前日期（两位，日累积）</p>
									<p>
										<span>{productType}</span
										>项目类型（获取项目信息中的项目类型）
									</p>
									<p>
										<span>{seqNo:000}</span>seqNo:位数（每年刷新,
										seqNo、typeSeqNo、allSeqNo只允许配一个）
									</p>
									<p>
										<span>{typeSeqNo:000}</span
										>typeSeqNo:位数（按项目类型，每年刷新，seqNo、typeSeqNo、allSeqNo只允许配一个）
									</p>
									<p>
										<span>{allSeqNo:000}</span>allSeqNo:位数（每年累加,
										seqNo、typeSeqNo、allSeqNo只允许配一个）
									</p>
									<p>
										<span>{trackNo:00}</span
										>trackNo:跟踪审查序号（每年刷新，不足补零）
									</p>
									<p>
										<span>{projectSn:000}</span
										>projectSn:项目序号（每年刷新，不足补零）
									</p>
									<p style="margin-top: 10px">
										例如：EC-{productType}-{year}{seqNo:000}
									</p>
									<p>结果：EC-MED-2019001</p>
								</div>
							</Poptip>
						</FormItem>
					</Col>
					<Col span="12" v-if="formData.types.includes('Textarea')">
						<FormItem label="文本框高度(行)" prop="rows">
							<InputNumber
								:max="1000"
								:min="1"
								v-model.trim="formData.rows"
								style="width: 100%"
							></InputNumber>
						</FormItem>
					</Col>
					<Col span="12" v-if="formData.types.includes('Textarea')">
						<FormItem label="文本框字符长度限制" prop="maxlength">
							<InputNumber
								:max="1000"
								:min="1"
								placeholder="请输入最大长度限制"
								v-model.trim="formData.maxlength"
								style="width: 100%"
							></InputNumber>
						</FormItem>
					</Col>
					<Col span="12" v-if="formData.bindType">
						<FormItem label="日期展示类型" prop="bindType">
							<Select v-model="formData.bindType">
								<Option value="date">日期</Option>
								<Option value="year">年</Option>
								<Option value="month">月</Option>
								<Option value="datetime">时间</Option>
							</Select>
						</FormItem>
					</Col>
					<Col span="24" v-if="signatureShow">
						<FormItem label="个人签名">
							<img :src="formData.img" />
						</FormItem>
					</Col>
					<!--复选框 选项 单行排列设置-->
					<Col
						span="24"
						v-if="
							formData.types.includes('Checkbox') ||
							formData.types.includes('Checkbox_List') ||
							formData.types.includes('Radio')
						"
					>
						<FormItem label="选项排列方式" prop="styles">
							<Select v-model="formData.styles">
								<Option :value="0">默认</Option>
								<Option :value="1">一列展示</Option>
							</Select>
						</FormItem>
					</Col>
					<Col
						span="24"
						v-if="
							(!formData.isRequest || formData.isRequest === '0') &&
							formData.optionList
						"
					>
						<FormItem :label="optionLabel" prop="optionList">
							<Row :gutter="8">
								<Col span="12"> 选项名称 </Col>
								<Col span="6"> 值 </Col>
								<Col span="3" style="text-align: center" v-if="requestShow">
									默认回填值
								</Col>
								<Col span="3" style="text-align: center"> 操作 </Col>
							</Row>
							<Row
								v-for="(op, idx) in formData.optionList"
								:key="idx"
								:gutter="8"
								class="opList"
							>
								<Col span="12">
									<Input
										v-model.trim="op.label"
										placeholder="请输入选项"
									></Input>
								</Col>
								<Col span="6">
									<Input v-model.trim="op.value" placeholder="请输入值"></Input>
								</Col>
								<Col span="3" style="text-align: center; cursor: pointer">
									<Checkbox
										v-if="
											formData.types.includes('Checkbox') ||
											formData.types.includes('Checkbox_List')
										"
										v-model="op.checked"
									></Checkbox>
									<Icon
										v-if="
											formData.types.includes('Radio') ||
											formData.types.includes('Select')
										"
										:style="{
											fontSize: '20px',
											color: `${op.checked ? '#fe834b' : '#666'}`
										}"
										@click="changeSelect(op, idx)"
										:type="
											!op.checked ? 'md-radio-button-off' : 'md-radio-button-on'
										"
									></Icon>
								</Col>
								<Col span="3" style="text-align: center">
									<Button
										type="primary"
										icon="md-remove"
										@click="formData.optionList.splice(idx, 1)"
									></Button>
								</Col>
							</Row>
						</FormItem>
						<FormItem style="text-align: center">
							<Button icon="ios-add" @click="addOption" type="primary"
								>添加选项
							</Button>
							<!--            <Button icon="ios-add">其他选项</Button>-->
						</FormItem>
					</Col>
					<Col span="24" v-if="!textPromptShow && !isTable">
						<FormItem label="验证规则" prop="customRules">
							<Row style="margin-bottom: 10px">
								<Col span="10"> 规则 </Col>
								<Col span="14"> 验证提示信息 </Col>
							</Row>
							<Row
								style="margin-bottom: 10px"
								v-for="(item, index) in formData.customRules"
								:key="index"
							>
								<Col span="6" style="padding-right: 15px">
									<Select
										style="width: 100%"
										v-model="item.label"
										@on-change="onRuleChange($event, item)"
									>
										<Option
											v-for="(op, index) in ruleList"
											:value="op.label"
											:key="index"
										>
											{{ op.label }}
										</Option>
									</Select>
								</Col>
								<Col span="14">
									<Input
										placeholder=""
										v-if="item"
										v-model.trim="item.rule.message"
									/>
								</Col>
								<Col span="4" style="padding-left: 15px">
									<Button @click="deleteRules(index)">删除</Button>
								</Col>
							</Row>
						</FormItem>
						<FormItem>
							<Col span="24" style="text-align: center">
								<Button icon="ios-add" @click="addRules" type="primary"
									>添加验证规则</Button
								>
							</Col>
						</FormItem>
					</Col>
					<Col span="24" v-if="isTable">
						<Button type="primary" @click="designTable">表格设计</Button>
					</Col>
				</Row>
			</Form>
			<div slot="footer">
				<Button style="margin-right: 8px" @click="onCancel">取消 </Button>
				<Button type="primary" @click="onOk" :loading="loading">确定 </Button>
			</div>
		</Modal>
		<TableDesign
			:visible="tableVisible"
			:tableObj="tableObj"
			@onOk="deSignOk"
			@onCancel="tableVisible = false"
		>
		</TableDesign>
	</div>
</template>

<script>
import api from "@/api/setting/formItem"
import publicApi from "@/api/public"
import controlOp from "./controlerList"
import TableDesign from "./TableDesign.vue"

const { addFormItem, updateFormItem, getDetail, getFormItemList } = api
const { publicGetDictionaryList } = publicApi

export default {
	name: "formItemAdd",
	props: ["visible", "updateId", "apiList", "roleList"],
	components: {
		TableDesign
	},
	data() {
		const validateOptionList = (rule, arr, callback) => {
			if (!arr.length) {
				callback()
			}
			const flag = arr.some(item => !item.value || !item.label)
			if (flag) {
				callback(new Error("该项必填"))
			} else {
				callback()
			}
		}
		const validateRuleList = (rule, arr, callback) => {
			if (!arr.length) {
				callback()
			}
			const flag = arr.some(item => !item.rule.message)
			if (flag) {
				callback(new Error("该项必填"))
			} else {
				callback()
			}
		}
		return {
			title: "新增组件配置",
			loading: false,
			tableVisible: false,
			textColor: "#515a6e",
			defaultConfig: {
				name: "",
				col: 8,
				placeholder: "请输入",
				global: "Y",
				isShowName: "Y",
				isFillRemark: "0",
				signType: 0,
				maxlength: null
				// isFilter: 0,
			},
			baseConfig: [
				"name",
				"col",
				"placeholder",
				"customRules",
				"code",
				"types",
				"tag",
				"global",
				"isFillRemark",
				"maxlength"
			],
			signatureConfig: [
				"name",
				"customRules",
				"code",
				"types",
				"tag",
				"global",
				"isFillRemark"
			],
			serviceConfig: [
				"autoGenerateRule",
				"isShowName",
				"signType",
				"signAlias"
			],
			formData: {
				types: [],
				maxlength: null
			},
			ruleValidate: {
				types: [
					{
						type: "array",
						required: true,
						message: "请选择",
						trigger: "blur change"
					}
				],
				name: [
					{
						required: true,
						message: "组件名称不能为空",
						trigger: "blur"
					}
				],
				tag: [
					{
						required: true,
						message: "所属标签不能为空",
						trigger: "blur"
					}
				],
				textPrompt: [
					{
						required: true,
						message: "纯文字提示信息内容不能为空",
						trigger: "blur"
					}
				],
				isProject: {
					required: true,
					message: "请选择是否项目信息",
					trigger: "change"
				},
				alias: [
					{
						required: true,
						message: "全局编码不能为空",
						trigger: "blur"
					}
				],
				bindChangeable: [
					{
						required: true,
						message: "请选择项目信息是否同步此数据",
						trigger: "blur change"
					}
				],
				apiCode: [
					{
						required: true,
						message: "请选择请求接口",
						trigger: "change"
					}
				],
				target: [
					{
						required: true,
						message: "请选择根据哪个组件进行联动",
						trigger: "change"
					}
				],
				operator: [
					{
						required: true,
						message: "请选择运算符",
						trigger: "change"
					}
				],
				targetVal: [
					{
						required: true,
						message: "联动组件值不能为空",
						trigger: "blur"
					}
				],
				autoGenerateRule: [
					{
						required: true,
						message: "编号生成规则不能为空",
						trigger: "blur"
					}
				],
				optionList: [
					{
						validator: validateOptionList,
						trigger: "blur change"
					}
				],
				customRules: [
					{
						validator: validateRuleList,
						trigger: "blur change"
					}
				]
			},
			baseList: controlOp.baseList,
			ruleList: controlOp.ruleList,
			serviceList: controlOp.serviceList,
			controlList: [],
			isDictionary: false,
			showProject: false,
			componentList: [],
			tableObj: {
				columns: [],
				rows: []
			},
			isText: true,
			isShowText: false
		}
	},
	computed: {
		env() {
			return this.$store.state.environment
		},
		optionLabel() {
			return this.formData.label && `${this.formData.label.substring(0, 2)}选项`
		},
		autoGenerateShow() {
			return (
				this.formData.types &&
				this.formData.types.includes("service") &&
				this.formData.types.includes("AutoGenerate")
			)
		},
		textPromptShow() {
			const textPrompt =
				this.formData.types &&
				this.formData.types.includes("service") &&
				this.formData.types.includes("textPrompt")
			return textPrompt
		},
		elecSignShow() {
			//  ， 儿童医院电子签名
			const elecSign =
				this.formData.types &&
				this.formData.types.includes("service") &&
				this.formData.types.includes("elecSign")
			return elecSign
		},
		operatorNameShow() {
			// 获取操作者姓名
			const operatorName =
				this.formData.types &&
				this.formData.types.includes("service") &&
				this.formData.types.includes("operatorName")
			return operatorName
		},
		isTable() {
			//  表格
			const table =
				this.formData.types.includes("base") &&
				this.formData.types.includes("Table")
			return table
		},
		signatureShow() {
			// 个人签名
			return this.formData.types && this.formData.types[1] === "Signature"
		},
		selectShow() {
			return this.formData.types && this.formData.types.includes("Select")
		},
		requestShow() {
			return (
				this.formData.types &&
				(this.formData.types.includes("Select") ||
					this.formData.types.includes("Radio") ||
					this.formData.types.includes("Checkbox_List") ||
					this.formData.types.includes("Checkbox"))
			)
		},
		systemShow() {
			return this.formData.types && this.formData.types.includes("system")
		},
		baseShow() {
			return this.formData.types && this.formData.types.includes("base")
		}
	},
	watch: {
		visible() {
			this.isShowText = false
			if (this.visible) {
				this.initComponentList()
				this.getAllTypeDataList()
				this.$refs.controlForm.resetFields()
				this.formData = {
					types: [],
					maxlength: null
				}
				if (this.updateId) {
					this.title = "修改组件配置"
					this.$asyncDo(async () => {
						this.loading = true
						const res = await getDetail(this.updateId)
						if (res && res.data) {
							const objData = JSON.parse(res.data.data)
							this.formData = {
								...this.formData,
								isFillRemark: this.defaultConfig.isFillRemark,
								...objData,
								maxlength: objData.maxlength || null
							}
							if (objData.type === "base_textPrompt") {
								this.isShowText = true
							} else if (objData.type === "elec_sign") {
								this.formData.signAlias = res.data.alias
							}
							this.textColor = this.formData.textColor || "#515a6e"
							if (
								this.formData.isProject === "1" &&
								this.formData.isRequest === "1"
							) {
								const apiItem = this.apiList.find(
									item => item.code === this.formData.apiCode
								)
								this.formData.apiUrl = apiItem.url
								this.formData.apiName = apiItem.name
							}
							if (this.formData.isRequest === "2") {
								const apiItem = this.roleList.find(
									item => item.id === this.formData.apiCode
								)
								this.formData.apiUrl = apiItem
									? `/auth/users/special-role?roleId=${apiItem.id}`
									: ""
								this.formData.apiName = apiItem ? apiItem.name : ""
								this.formData.apiCode = apiItem ? this.formData.apiCode : ""
								this.formData.isFillRemark = "0"
							}
							if (this.formData.optionList && this.formData.optionList.length) {
								this.formData.optionList.forEach((item, index) => {
									if (!item.checked)
										this.$set(this.formData.optionList[index], "checked", false)
								})
							}
							this.$nextTick(() => {
								this.handleChangePro(this.formData.isProject)
							})
						}
						this.loading = false
					})
				} else {
					this.title = "新增组件配置"
					const code = new Date().getTime().toString(32).toUpperCase()
					this.formData = {
						...this.defaultConfig,
						code,
						types: [],
						customRules: [],
						[`${code}`]: []
					}
				}
			}
		}
	},
	methods: {
		async initComponentList() {
			this.loading = true
			// del:0,表示启用；isFilter:1,表示只筛选select，radio和CheckBox
			const res = await getFormItemList({ del: 0, isFilter: 1 })

			if (res) {
				this.componentList = res.data || []
				if (this.updateId) {
					this.componentList = this.componentList.filter(
						item => item.id !== this.updateId
					)
				}
				this.componentList = this.componentList.map(item => {
					let json = {}
					let obj = { ...item }
					if (item.data) {
						if (typeof item.data === "object") {
							json = item.data
						} else {
							json = JSON.parse(item.data)
						}
						obj = {
							...item,
							code: json.code
						}
					}
					return obj
				})
			}
			this.loading = false
		},
		handleChangeRequestType(val) {
			if (!val) return
			if (val !== "0") {
				this.formData.optionList = []
			} else {
				this.formData.apiUrl = ""
				this.formData.apiName = ""
				this.formData.apiCode = ""
			}
		},
		// 切换请求接口
		handleChangeRequest(val, type) {
			if (!val) return
			if (type === "role") {
				const apiItem = this.roleList.find(item => item.id === val)
				this.formData.apiUrl = apiItem
					? `/auth/users/special-role?roleId=${val}`
					: ""
				this.formData.apiName = apiItem ? apiItem.name : ""
				this.formData.apiCode = apiItem ? this.formData.apiCode : ""
			} else {
				const apiItem = this.apiList.find(item => item.code === val)
				this.formData.apiUrl = apiItem.url
				this.formData.apiName = apiItem.name
			}
		},
		// 是否项目信息
		handleChangePro(val) {
			this.showProject = false
			if (val !== "1") {
				this.formData.bindChangeable = ""
				this.ruleValidate.alias = []
			} else {
				this.ruleValidate.alias = [
					{
						required: true,
						message: "全局编码不能为空",
						trigger: "blur"
					}
				]
			}
			this.$nextTick(() => {
				this.showProject = true
			})
		},
		// 切换组件类型
		onControlChange(arr, selectedData) {
			this.isDictionary = !!arr.includes("dictionary")
			this.isText = !!arr.includes("textPrompt")
			this.isShowText = !!arr.includes("textPrompt")
			const { code } = this.formData
			let config = this.baseConfig
			if (arr.includes("Signature")) {
				config = this.signatureConfig
			} else if (arr.includes("service")) {
				config = [...config, ...this.serviceConfig]
			}
			const obj = {}
			config.forEach(key => {
				obj[key] = this.formData[key] || this.defaultConfig[key]
			})
			Object.keys(selectedData[1]).forEach(key => {
				if (!key.includes("value") && key !== "__label") {
					obj[key] = selectedData[1][key]
				}
			})
			if (this.formData[code][0]) {
				if (arr[1] === "Date") {
					this.formData[code][0].type = "date"
				} else if (arr[1] === "Checkbox" || arr[1] === "Checkbox_List") {
					this.formData[code][0].type = "array"
				} else {
					this.formData[code][0].type = "string"
				}
			}
			if (arr.includes("Table") || arr.includes("elecSign")) {
				this.$set(obj, "col", 24)
			}
			this.formData = {
				...obj,
				[`${code}`]: this.formData[code]
			}
		},
		// 表格设计
		designTable() {
			this.tableObj = this.formData.tableObj
			this.tableVisible = true
		},
		// 表格设计完成
		deSignOk(obj) {
			this.formData = {
				...this.formData,
				tableObj: obj
			}
		},
		addRules() {
			this.formData.customRules.push({ rule: {}, label: "" })
		},
		deleteRules(index) {
			this.formData.customRules.splice(index, 1)
			this.formData[this.formData.code].splice(index, 1)
		},
		// 改变组件单选中单选选项选择状态
		changeSelect(item, index) {
			this.formData.optionList.forEach((dataItem, idx) => {
				if (index !== idx) {
					dataItem.checked = false
				}
			})
			const oldVal = !!this.formData.optionList[index].checked
			this.$set(this.formData.optionList[index], "checked", !oldVal)
		},
		addOption() {
			this.formData.optionList.push({
				value: `${this.formData.optionList.length + 1}`,
				label: "",
				checked: false
			})
		},
		onRuleChange(value, obj) {
			if (!value) {
				return
			}
			const ruleObj = this.ruleList.find(el => el.label === value)
			const newRuleObj = JSON.parse(JSON.stringify(ruleObj))
			if (this.formData.type === "base_Date") {
				newRuleObj.rule.type = "date"
			} else if (
				this.formData.type === "base_Checkbox" ||
				this.formData.type === "base_Checkbox_List"
			) {
				newRuleObj.rule.type = "array"
			} else {
				newRuleObj.rule.type = "string"
			}
			Object.assign(obj, newRuleObj)
			this.formData[this.formData.code].push(obj.rule)
		},
		getAllTypeDataList() {
			this.getDictionaryList().then(res => {
				const newServiceList = this.serviceList.filter(
					item =>
						//  人民，有CA电子签名
						// if (this.env !== 'xinhua' && this.env !== 'shenzhenrenmin') {
						//   return item.type !== 'elec_sign';
						// }
						true
				)
				this.controlList = [
					{
						label: "基础数据",
						value: "base",
						children: this.baseList
					},
					{
						label: "业务数据",
						value: "service",
						children: newServiceList
					},
					{
						label: "字典数据",
						value: "dictionary",
						children: res
					}
				]
			})
		},
		async getDictionaryList() {
			const res = await publicGetDictionaryList()
			if (res) {
				const list = res.data.filter(item => item.leaf.length)
				return list.map(item => ({
					label: item.name,
					id: item.id,
					type: "base_Select",
					value: item.id
				}))
			}
			return []
		},
		onOk() {
			this.$refs.controlForm.validate(valid => {
				if (valid) {
					if (this.formData.customRules) {
						this.formData.customRules = this.formData.customRules.map(rule => {
							if (rule.rule && rule.rule.pattern) {
								Object.assign(rule.rule, {
									// pattern: rule.rule.pattern.toString(),
									pattern: String(rule.rule.pattern)
								})
							}
							return rule
						})
					}
					const paramsData = { ...this.formData }
					let { alias } = this.formData
					if (this.formData.isLinkage === "1") {
						paramsData.visExpr = `${this.formData.target} ${this.formData.operator} ${
							this.formData.targetVal
						}`
					} else {
						paramsData.visExpr = ""
						paramsData.target = ""
						paramsData.operator = ""
						paramsData.targetVal = ""
					}
					if (this.formData.types.includes("textPrompt")) {
						// 纯文字提示信息
						paramsData.textColor = this.textColor
					}
					if (this.formData.types.includes("elecSign")) {
						// 电子签名 人民医院电子签名
						// paramsData.isShowName = paramsData.signType === 1 ? 'N' : 'Y'; // 自动签名，不显示label，手动签名显示。
						paramsData.isProject = "0"
						paramsData.global = "N"
						paramsData.col = 24
						alias = this.formData.signAlias
					}
					if (this.formData.types.includes("operatorName")) {
						// 获取操作者姓名
						paramsData.isProject = "0"
						paramsData.global = "N"
						paramsData.col = 24
						alias = this.formData.operatorAlias
					}
					if (this.formData.isRequest === "2") {
						// 角色接口，不允许回填到审批内容
						paramsData.isFillRemark = "0"
					}
					this.$asyncDo(async () => {
						this.loading = true
						if (this.updateId) {
							const res = await updateFormItem({
								name: this.formData.name,
								data: paramsData,
								id: this.updateId,
								tag: this.formData.tag,
								alias
							})
							if (res) {
								this.$Message.success("修改成功")
								this.$emit("onOk")
							}
						} else {
							const res = await addFormItem({
								name: this.formData.name,
								data: paramsData,
								tag: this.formData.tag,
								alias
							})
							if (res) {
								this.$Message.success("新增成功")
								this.$emit("onOk")
							}
						}
						this.showProject = false
						this.loading = false
					})
				}
			})
		},
		onCancel() {
			this.showProject = false
			this.$emit("onCancel")
		}
	}
}
</script>

<style lang="less">
.control {
	/*.alignCenter {*/
	/*text-align: center;*/
	/*}*/
	// 抽屉内容
	.ivu-drawer-body {
		padding-bottom: 55px;
		position: static;
		.ivu-form-label-top {
			.ivu-form-item-label {
				color: #333;
				font-weight: 600;
			}
		}
		& > div:first-child {
			padding-bottom: 50px;
		}
		.ivu-form-label-top .ivu-form-item-label {
			color: #333333;
			font-weight: 600;
		}
		/*颜色选择器*/
		.color-picker-wrap {
			.ivu-select-dropdown .ivu-input-wrapper {
				width: 195px;
				.ivu-input-small {
					height: 26px;
				}
			}
		}
	}
	.opList {
		margin-bottom: 24px;
		.ivu-checkbox-inner {
			border-color: #8a8a8a;
		}
	}
	// 抽屉页脚
	.drawer-footer {
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		border-top: 1px solid #e8e8e8;
		padding: 10px 16px;
		text-align: right;
		background: #fff;
	}
	.linkage-rule-wrap {
		.title-rule {
			font-weight: bold;
			font-size: 14px;
			margin-bottom: 10px;
			color: #333;
		}
		.ivu-form-item .ivu-form-item-label {
			color: #666;
			font-weight: 400;
		}
		.linkage-select {
			width: 100%;
			text-align: left;
		}
	}
}
.pop-rule {
	width: 100%;
	.ivu-poptip-rel {
		width: 100%;
	}
}
.formitem-tooltip-content {
	padding: 10px;
	font-size: 12px;
	color: #333;
	p {
		line-height: 20px;
		white-space: nowrap;
		span {
			width: 95px;
			display: inline-block;
			margin-right: 5px;
		}
	}
}
</style>
