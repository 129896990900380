const baseList = [
	/** base开头的为基础数据
	 * dictionary开头数据字典  selectList为动态获取绑定关联数据项
	 * * */
	// {
	//   type: 'base_Input',
	//   label: '单行文本',
	// },
	{
		label: "多行文本",
		value: "Textarea",
		type: "base_Textarea",
		rows: 2
	},
	{
		label: "日期选择器",
		value: "Date",
		type: "base_Date",
		bindType: "date"
	},
	{
		label: "下拉选择器",
		type: "base_Select",
		value: "Select",
		optionList: []
	},
	{
		type: "base_Radio",
		value: "Radio",
		label: "单选框",
		optionList: [],
		styles: 0
	},
	{
		type: "base_Checkbox",
		value: "Checkbox",
		label: "复选框",
		optionList: [],
		styles: 0
	},
	{
		type: "base_Checkbox_List",
		value: "Checkbox_List",
		label: "复选框单行排列",
		optionList: [],
		styles: 0
	},
	{
		type: "base_Table",
		value: "Table",
		label: "表格",
		tableObj: {
			columns: [],
			rows: []
		}
	}
]

const serviceList = [
	{
		// type: 'Signature',
		value: "Signature",
		label: "个人签名",
		img: `${process.env.VUE_APP_PUBLIC_BASE_URL}resource/images/common/signature.jpg`
	},
	{
		type: "base_Textarea",
		value: "AutoGenerate",
		label: "规则自动生成编号",
		autoGenerateType: "autoGenerateNo" // 后端使用的字段
	},
	{
		label: "文字提示",
		value: "textPrompt",
		type: "base_textPrompt"
	},
  {
    label: "电子签名",
    value: "elecSign",
    type: "elec_sign"
  },
  {
    label: "获取操作者姓名",
    value: "operatorName",
    type: "operator_name"
  }
	/* {
    type: 'base_Textarea',
    value: 'ProjectAccept',
    label: '机构受理号',
    autoGenerateType: 'acceptNo', // 后端使用的字段
  },
  {
    type: 'base_Textarea',
    value: 'ProjectApproval',
    label: '机构立项号',
    autoGenerateType: 'approveNo', // 后端使用的字段
  },
  {
    type: 'base_Textarea',
    value: 'EthicsAccept',
    autoGenerateType: 'ethicsAcceptNo', // 后端使用的字段
    label: '伦理受理号',
  },
  {
    type: 'base_Textarea',
    value: 'EthicsApprove',
    autoGenerateType: 'ethicsApproveNo', // 后端使用的字段
    label: '伦理批件号',
  }, */
]
// 验证规则可在该数组添加验证规则
const ruleList = [
	{
		rule: {
			required: true,
			message: "此项必填",
			trigger: "blur change"
		},
		label: "必填项"
	}
]
export default { ruleList, baseList, serviceList }
